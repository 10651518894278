#singlePost {
  border-radius: 5px;
  background-color: rgba(171, 232, 236, 0.301);
  padding: 10px;
  width: 60%;
  max-height: 750px;
  margin: 10px;
  margin-left: 20%;
  margin-right: 20%;
  border-style: solid;
  border-width: 1px;
}

#addPostAccordion {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  width: 60%;
  left: 20%;
}

#addCommentAccordion {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
}

label {
  width: 100%;
}

#commentForm {
  border-radius: 5px;
  background-color: rgba(145, 233, 164, 0.301);
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
}

#singleComment {
  border-radius: 5px;
  background-color: rgba(209, 149, 241, 0.192);
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  width: 98%;
  max-height: 200px;
  margin-top: 10px;
}

#postsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
}

#header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

#commentsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 140px;
  overflow-y: auto;
  margin-top: 10px;
}

#titleContainer {
  font-size: 25px;
  max-width: 100%;
  height: 40px;
  overflow-y: auto;
  border-bottom: solid;
  font-weight: bold;
  padding-left: 10px;
}

#lineSeparated {
  font-weight: bold;
  border-bottom: solid;
  border-width: 1px;
}

#commentContent {
  width: 100%;
  height: 100px;
  resize: none;
}

#usernameContainer {
  font-size: 12px;
  font-style: italic;
  height: 25px;
  width: fit-content;
  max-width: 80%;
  overflow-y: auto;
  padding: 3px;
  margin-top: 5px;
}

#timestampContainer {
  text-align: right;
  font-size: 12px;
  height: 25px;
  overflow-y: auto;
  border-radius: 2px;
  padding: 3px;
  margin-left: 20px;
  margin-top: 5px;
}

#usernameTimestampContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#contentContainer {
  font-size: 15px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.863);
  border-radius: 2px;
  padding: 3px;
  margin-top: 5px;
  overflow-y: auto;
}

.commentContainer {
  max-height: 50px;
}

.postContainer {
  max-height: 130px;
}

#newPostForm {
  border-radius: 5px;
  border-style: solid;
  height: fit-content;
  border-width: 1px;
  margin: 10px;
  background-color: rgba(233, 188, 145, 0.301);
  padding: 10px;
  font-size: 14px;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

label {
  width: 100%;
}

#titleinput {
  width: 97%;
}

#postContent {
  width: 100%;
  height: 100px;
}

#centered {
  text-align: center;
}

#outer {
  position: absolute;
  width: 100%;
  height: 100%;
}
